<template lang="pug">
  #videowidgetapp(v-show="showWidget" ref="widget" :class="{ active: isActive}" :style="{ borderColor : widgetColors.border, fontFamily: widgetTitleFont.font + ' !important', borderRadius: widgetBorderRadius }")
    .widget(v-show="!isActive" @click="showPresentation")
      .widget-title(v-if="caption" :style="{left: widgetTitlePos.left, top: widgetTitlePos.top, fontSize: widgetTitleFont.size, fontWeight: widgetTitleFont.weight, color: widgetTitleFont.color}") {{caption}}
      .video-container(v-if="slides.length" :style="{ left: mainVideo.left, top: mainVideo.top }")
        .video(v-for='(video, index) in slides', :key='index')
          video(v-if="index === 0" :ref="'video' + index" autoplay loop muted="muted" @loadedmetadata="setSizes(index, $event)")
            source(:src="video.src" type="video/mp4")
          video(v-else :ref="'video' + index" @loadedmetadata="setSizes(index, $event)")
            source(:src="video.src" type="video/mp4")
    .widget(v-show="isActive")
      button.close-btn(title="Закрыть" @click.stop="hidePresentation")
        span ×
      .video-progress
        .video-progress-bar(v-for="(bar, index) in slides.length" :key='index' :style="{width: 100 / slides.length + '%'}")
          progress.progress-bar(
            value="0"
            max="100"
            :ref="'progress' + index"
          )
      .video-controls
        .video-controls-btns
          button.play-btn(ref="playBtn" @click="videoPlay")
          button.volume-btn(ref="volumeBtn" @click="videoVolume")
          button.share-btn(@click="share" title="Поделиться веб-страницей")
      .slides
        transition-group(
          name='slide'
          mode='out-in'
          enter-class='slide-in'
          leave-class='slide-out'
          enter-active-class='animated slide-in-active'
          leave-active-class='animated slide-out-active'
        )
          .slides-item(
            v-for='(slide, index) in slides'
            v-show="index == activeSlide"
            :key='index'
            :style="{ width: slides[0].width, height: slides[0].height }"
          )
            VideoSlide(
              :number="index"
              :clips="slides"
              :btns="btnLinks[index]"
              :widgetFont="widgetTitleFont"
              :activeSlide="activeSlide"
              :linkColor="widgetColors.linkColor"
              @isLoadVideo="checkLoadVideo"
              @playOver="move(1)"
              @setProgress="progressUpdate"
            )
      span.prev(v-if="qtyClipSlides > 1" @click='move(-1)') &#8249;
      span.next(v-if="qtyClipSlides > 1" @click='move(1)') &#8250;
      ul.widget-dots(v-if="qtyClipSlides > 1"  :style="{ width: slides[0].width + 'px'}" )
        li(
          v-for='(dot, index) in slides'
          :class='{ active: index === activeSlide }'
          @click='jump(index)'
          :style="{backgroundColor: widgetColors.linkColor}"
        )
      .share-overlay(ref="shareoverlay" @click="hideShareWindow")
      .share-window(ref="sharewindow")
        p Поделитесь этой страницей:<br/>{{sharing.title}}
        vue-goodshare-email(has_icon)
        vue-goodshare-facebook(has_icon)
        vue-goodshare-vkontakte(has_icon)
        vue-goodshare-odnoklassniki(has_icon)
        vue-goodshare-linkedin(has_icon)
        vue-goodshare-twitter(has_icon)
        vue-goodshare-telegram(has_icon)
        vue-goodshare-viber(has_icon)
        vue-goodshare-whatsapp(has_icon)
</template>

<script>
import VueGoodshareEmail from "vue-goodshare/src/providers/Email.vue"
import VueGoodshareFacebook from "vue-goodshare/src/providers/Facebook.vue"
import VueGoodshareVkontakte from "vue-goodshare/src/providers/Vkontakte.vue"
import VueGoodshareOdnoklassniki from "vue-goodshare/src/providers/Odnoklassniki.vue"
import VueGoodshareLinkedin from "vue-goodshare/src/providers/LinkedIn.vue"
import VueGoodshareTwitter from "vue-goodshare/src/providers/Twitter.vue"
import VueGoodshareTelegram from "vue-goodshare/src/providers/Telegram.vue"
import VueGoodshareViber from "vue-goodshare/src/providers/Viber.vue"
import VueGoodshareWhatsapp from "vue-goodshare/src/providers/WhatsApp.vue"

export default {
  name: 'App',
  props: [
    'src',
    'mainvideo',
    'position',
    'shape',
    'colors',
    'caption',
    'captionpos',
    'captionfont',
    'linksname',
    'linksurl'
  ],
  components: {
    VueGoodshareEmail,
    VueGoodshareFacebook,
    VueGoodshareVkontakte,
    VueGoodshareOdnoklassniki,
    VueGoodshareLinkedin,
    VueGoodshareTwitter,
    VueGoodshareTelegram,
    VueGoodshareViber,
    VueGoodshareWhatsapp,
    VideoSlide: () => import('./components/VideoSlide.vue')
  },
  data: () => ({
    showWidget: false,
    isActive: false,
    qtyLoadedVideo: 0,
    slides: [],
    activeSlide: 0,
    mainVideo: {width: 100, height: 100, left: 0, top: 0},
    sharing: {
      url: window.document.location.href,
      title: window.document.title,
      description: window.document.description
    }
  }),
  created() {
    this.src.split(',').forEach( (video, index) => {
      this.slides[index] = { src: video, aspectRatio: 1 }
    })
  },
  computed: {
    qtyClipSlides() {
      return this.src.split(',').length
    },
    widgetBorderRadius() {
      return this.shape.split(',')[0] === 'circle' ? '50%' : '0'
    },
    widgetWidth() {
      return this.shape.split(',')[1] + 'px'
    },
    widgetHeight() {
      return this.shape.split(',')[1] + 'px'
    },
    widgetTitlePos() {
      return {
        left: this.captionpos.split(',')[0] + '%',
        top: this.captionpos.split(',')[1] + '%'
      }
    },
    widgetTitleFont() {
      return {
        font: this.captionfont.split(',')[0],
        size: this.captionfont.split(',')[1] + 'px',
        weight: this.captionfont.split(',')[2],
        color: this.captionfont.split(',')[3]
      }
    },
    widgetColors() {
      const colors = {}
      if (this.colors) {
        colors.border = this.colors.split(',')[0]
      } else {
        colors.border = '#696E7D'
      }
      if (this.colors.split(',')[1]) {
        colors.linkColor = this.colors.split(',')[1]
      } else {
        colors.linkColor = '#0075ff'
      }
      return colors
    },
    btnLinks() {
      const links = []
      if (this.linksname && this.linksurl) {
        this.linksname.split(';').forEach( (names, index) => {
          links[index] = []
          names.split(',').forEach( (name, idx) => {
            links[index][idx] = {
              id: idx,
              name,
              url: ''
            }
          })
        })
        this.linksurl.split(';').forEach( (urls, index) => {
          urls.split(',').forEach( (url, idx) => {
            links[index].forEach( btn => {
              if (btn.id === idx) {
                btn.url = url
              }
            })
          })
        })
      }
      return links
    }
  },
  mounted() {
    this.$refs.widget.style.width = this.widgetWidth
    this.$refs.widget.style.height = this.widgetHeight

    if (this.position) {
      if (this.position === 'left') {
        this.$refs.widget.style.left = '25px'
      } else {
        this.$refs.widget.style.right = '25px'
      }
    } else {
      this.$refs.widget.style.left = '25px'
    }
  },
  methods: {
    checkLoadVideo() {
      this.qtyLoadedVideo++
      if (this.qtyLoadedVideo === this.qtyClipSlides) this.showWidget = true
    },
    setSizes(index, event) {
      const widthWindow = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
            heightWindow = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

      const widthVideo = event.target.videoWidth,
          heightVideo = event.target.videoHeight,
          aspectRatio = widthVideo / heightVideo
      let verticalIndent = 50
      if (widthWindow <= 480) verticalIndent = 0

      if (heightVideo > heightWindow - verticalIndent) {
        this.slides[index].height = heightWindow - verticalIndent
        this.slides[index].width = Math.trunc((heightWindow - verticalIndent) * aspectRatio)
      } else {
        this.slides[index].width = widthVideo
        this.slides[index].height = heightVideo
      }
      this.slides[index].aspectRatio = aspectRatio

      if ( index === 0 ) {
        this.mainVideo.width = Math.trunc(this.slides[0].width * Number(this.mainvideo.split(',')[2]))
        this.mainVideo.height = Math.trunc(this.slides[0].height * Number(this.mainvideo.split(',')[2]))
        event.target.setAttribute( 'width', this.mainVideo.width )
        event.target.setAttribute('height', this.mainVideo.height )

        this.mainVideo.left = '-' + ( Number(this.mainvideo.split(',')[0]) * this.mainVideo.width / 100) + 'px'
        this.mainVideo.top = '-' + ( Number(this.mainvideo.split(',')[1]) * this.mainVideo.height / 100) + 'px'
      }
    },
    move(amount) {
      this.$refs.playBtn.classList.remove('pause')
      document.querySelectorAll('.clip')[this.activeSlide].pause()
      this.progressUpdate('100', this.activeSlide)

      let newActive = this.activeSlide + amount
      if (newActive > this.slides.length - 1) newActive = 0
      if (newActive === -1) newActive = this.slides.length - 1
      this.activeSlide = newActive

      const newClip = document.querySelectorAll('.clip')[this.activeSlide]
      newClip.currentTime = 0
      newClip.play()

      if (this.$refs.volumeBtn.classList.contains('non')) {
        newClip.volume = 0
      } else {
        newClip.volume = 1
      }
    },
    jump(index) {
      this.activeSlide = index
      document.querySelectorAll('.clip').forEach( (video, idx) => {
        if (idx !== index) {
          video.pause()
          video.currentTime = 0
        } else {
          video.currentTime = 0
          video.play()
          if (this.$refs.playBtn.classList.contains('pause')) {
            this.$refs.playBtn.classList.remove('pause')
          }
          if (this.$refs.volumeBtn.classList.contains('non')) {
            video.volume = 0
          } else {
            video.volume = 1
          }
        }
      })
    },
    showPresentation() {
      this.isActive = true
      this.$refs.widget.style.width = this.slides[0].width + 'px'
      this.$refs.widget.style.height = this.slides[0].height + 'px'
      const widthWindow = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      this.$refs.widget.style.bottom = widthWindow > 480 ? '25px' : '0'

      if (this.position) {
        if (this.position === 'left') {
          this.$refs.widget.style.left = widthWindow > 480 ? '25px' : '0'
        } else {
          this.$refs.widget.style.right = widthWindow > 480 ? '25px' : '0'
        }
      } else {
        this.$refs.widget.style.left = widthWindow > 480 ? '25px' : '0'
      }

      const firstClip = document.querySelectorAll('.clip')[0]
      firstClip.play()
      firstClip.volume = 1

      this.slides.forEach( (clip, index) => {
        if (index !== 0 && Number(clip.width) > Number(this.slides[0].width)) {
          clip.width = this.slides[0].width
          clip.height = Math.trunc(this.slides[0].width / clip.aspectRatio)
        }
      })
    },
    hidePresentation() {
      document.querySelectorAll('.clip').forEach( (video) => {
        video.pause()
        video.currentTime = 0
        video.volume = 0
      })
      document.querySelectorAll('.progress-bar').forEach( bar => {
        bar.value = 0
      })
      this.$refs.playBtn.classList.remove('pause')
      this.$refs.volumeBtn.classList.remove('non')

      this.isActive = false
      this.activeSlide = 0

      this.$refs.widget.style.width = this.widgetWidth
      this.$refs.widget.style.height = this.widgetHeight
      if (this.position) {
        if (this.position === 'left') {
          this.$refs.widget.style.left = '25px'
        } else {
          this.$refs.widget.style.right = '25px'
        }
      } else {
        this.$refs.widget.style.left = '25px'
      }

      this.$refs["video0"][0].setAttribute( 'width', this.mainVideo.width )
      this.$refs["video0"][0].setAttribute( 'height', this.mainVideo.height )
    },
    videoPlay(event) {
      event.target.classList.toggle('pause')
      if (event.target.classList.contains('pause')) {
        document.querySelectorAll('.clip')[this.activeSlide].pause()
      } else {
        document.querySelectorAll('.clip')[this.activeSlide].play()
      }
    },
    videoVolume(event) {
      event.target.classList.toggle('non')
      if (event.target.classList.contains('non')) {
        document.querySelectorAll('.clip')[this.activeSlide].volume = 0
      } else {
        document.querySelectorAll('.clip')[this.activeSlide].volume = 1
      }
    },
    share() {
      if (navigator.share) {
          console.log("Congrats! Your browser supports Web Share API")
          navigator.share({
                  title: this.sharing.title,
                  text: this.sharing.description,
                  url: this.sharing.url
              })
              .then(function () {
                  console.log("Thanks for sharing!")
              })
              .catch( console.error )

      } else {
        this.$refs.shareoverlay.classList.add('share-display')
        this.$refs.sharewindow.classList.add('share-display')
      }
    },
    hideShareWindow() {
      this.$refs.shareoverlay.classList.remove('share-display')
      this.$refs.sharewindow.classList.remove('share-display')
    },
    progressUpdate(value, number) {
      this.$refs['progress' + number][0].setAttribute('value', value)
    }
  }
}
</script>

<style lang="scss">
#videowidgetapp * {
  box-sizing: border-box;
  user-select: none;
}
#videowidgetapp button {
  appearance: none; /*  Turn off standard styling  */
  border: none;
  outline: none;
  cursor: pointer;
}
#videowidgetapp button:disabled, button[disabled] {
  background-color: #dedede;
  // cursor: not-allowed;
  opacity: 0.2;
  cursor: no-drop;
  &:hover {
    background-color: #dedede;
  }
}
#videowidgetapp {
  position: fixed;
  bottom: 25px;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  cursor: pointer;
  z-index: 9999;

  .widget {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(228,228,228,1);

    .widget-title {
      position: absolute;
      z-index: 6;
      padding: 3px 10px 8px;
      border-radius: 10px;
      letter-spacing: -1px;
      background-color: rgba(228,228,228,0.7);
      text-align: center;
      line-height: 1;
    }

    .video-progress {
      position: absolute;
      top: 40px;
      left: 0;
      z-index: 5;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 1.5%;
      opacity: 0.9;

      .video-progress-bar {
        margin: auto;
        text-align: center;
        border-radius: 3px;
        height: 6px;
        padding: 0 1.5%;

        progress {
          width: 100%;
          margin: 0;
          border: 0;
          height: 6px;
          border-radius: 3px;
          overflow: hidden;
          background-color: rgba(255,255,255,0.3);
          -webkit-appearance: none;
          appearance: none;

          &::-webkit-progress-bar {
            border-radius: 3px;
            background-color: rgba(255,255,255,0.3);
          }
          &::-webkit-progress-value {
            background-color: white;
          }
          &::-moz-progress-bar {
            background-color: white;
          }
        }
      }
    }

    .video-controls {
      position: absolute;
      top: 70px;
      left: 0;
      z-index: 5;
      width: 100%;
      text-align: center;

      .video-controls-btns {
        display: inline-block;
        text-align: center;
        padding: 6px 12px;
        border-radius: 24px;
        background-color: rgba(228,228,228,0.3);
      }

      & button {
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px;
      }

      .play-btn {
        margin: 0 13px 0 10px;
        width: 22px;
        height: 26px;
        background: url('./assets/pause.svg');
        background-repeat: no-repeat;
        background-size:contain;
        &.pause {
          background: url('./assets/play.svg');
          background-repeat: no-repeat;
          background-size:contain;
        }
      }
      .volume-btn {
        width: 37px;
        height: 37px;
        background: url('./assets/volume.svg');
        background-repeat: no-repeat;
        background-size:contain;
        &.non {
          background: url('./assets/volume-non.svg');
          background-repeat: no-repeat;
          background-size:contain;
        }
      }
      .share-btn {
        width: 25px;
        height: 25px;
        background: url('./assets/share.svg');
        background-repeat: no-repeat;
        background-size:contain;
      }
    }

    .video-container {
      position: absolute;
      z-index: 2;
    }

    .close-btn {
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 40px;
      background-color: rgba(255,255,255,0.7);
      border-radius: 0px 0px 0px 30px / 0px 0px 0px 30px;
      // text-align: center;
      z-index: 3;
      transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);

      span {
        font-size: 40px;
        line-height: 0.6;
        margin-left: 5px;
        font-family: 'Times New Roman'
      }

      &:hover {
        width: 44px;
        height: 44px;
        border-radius: 0px 0px 0px 34px / 0px 0px 0px 34px;
      }
    }

    .widget-btns-group {
      position: absolute;
      left: 0;
      bottom: 80px;
      z-index: 6;
      width: 100%;
      text-align: center;
    }
  }
  &.active {
    padding: 0;
    border-radius: 0;
    border-width: 0;
    border-radius: 0 !important;
    cursor: default;

    .widget {
      background-color: #363636;
    }
  }
}

#videowidgetapp .prev, #videowidgetapp .next {
  position: absolute;
  z-index: 4;
  top: 50%;
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
  font-size: 40px;
  color: #fff;
  border-radius: 50%;
  margin-top: -25px;
  margin-left: 5px;
  cursor: pointer;
  line-height: 36px;
  text-align: center;
  text-indent: -2px;
  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:hover {
    background: rgba(0,0,0,0.3);
    color: #fff;
    transform: scale(1.2);
  }

  &:active {
    transform: translate(0, 3px) scale(1.2);
  }
}

#videowidgetapp .next {
  right: 0;
  margin-left: auto;
  margin-right: 5px;
  text-indent: 2px;
}

#videowidgetapp .widget-dots {
  position: absolute;
  z-index: 4;
  display: block;
  margin: 0;
  padding: 0;
  text-align: center;
  bottom: 20px;

  li {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    opacity: 0.4;
    display: inline-block;
    margin: 0 3px;
    cursor: pointer;
    transition: opacity 0.4s ease-in-out,width 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &.active {
      width: 22px;
      opacity: 1;
    }
  }
}

#videowidgetapp .share-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  display: none;
  background-color: rgba(0,0,0,0.5);
}

#videowidgetapp .share-window {
  position: absolute;
  top: 10%;
  left: 5%;
  z-index: 21;
  display: none;
  width: 90%;
  height: auto;
  background-color: #fff;
  padding: 50px 40px 70px;
  text-align: center;

  .button-social {
    margin: 15px 15px;

    &.whatsapp__design__flat {
      background-color: #18bf02;

      .icon-whatsapp:before {
        font-size: 18px;
      }
    }
  }
}

#videowidgetapp .share-display {
  display: block;
}

#videowidgetapp .slides {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  .animated {
    transition: all 90ms;
    position: absolute;
    top: 0;
    left: 0;
  }
  .slide-in {
    opacity: 0;
    left: 75%;
  }

  .slide-in-active {
    transition-delay: 20ms;
  }
  .slide-out {
    opacity: 1;
  }

  .slide-out-active {
    opacity: 0;
    left: -75%;
  }
}

#videowidgetapp .buttons {
  position: absolute;
  top: 10px;
  left: 10px;
}
</style>
