<template lang="pug">
  .video-slide
    .video-container
      video.clip(
        @loadeddata="$emit('isLoadVideo')"
        @ended="$emit('playOver')"
        @timeupdate="timeUpdate"
        @pause="stopClip(number)"
        @playing="createBackground(number, $event)"
      )
        source(:src="`${clipSrc}`" type="video/mp4")
      .clip-bg
        canvas.clip-canvas
        img.clip-bgimage
    .widget-btns-group(v-show="btns.length")
      a.widget-btn(
        v-for="(btn, index) in btns"
        :key="index"
        v-if="btn.name !== 'null'"
        :href="btn.url"
        target="_blank"
        :style="{color: linkColor, backgroundColor: '#DEDEDE', fontFamily: widgetFont.font + ' !important'}"
        @mouseover="changeLookBtn('in', $event)"
        @mouseout="changeLookBtn('out', $event)"
      ) {{btn.name}}
</template>

<script>
export default {
  name: 'VideoSlide',
  props: {
    number: Number,
    activeSlide: Number,
    clips: Array,
    btns: Array,
    widgetFont: Object,
    linkColor: String
  },
  computed: {
    clipSrc() {
      return this.clips[this.number].src
    }
  },
  methods: {
    timeUpdate(e) {
      const progressValue = e.target.currentTime / e.target.duration * 100
      this.$emit( 'setProgress', progressValue, this.number )
    },
    stopClip(number) {
      if (number !== this.activeSlide) {
        this.$emit( 'setProgress', '100', this.number )
      }
    },
    changeLookBtn(action, event) {
      if (action === 'in') {
        event.target.style.backgroundColor = this.linkColor
        event.target.style.color = 'white'
      } else {
        event.target.style.backgroundColor = 'white'
        event.target.style.color = this.linkColor
      }
    },
    createBackground(number, event) {
      event.target.setAttribute('width', this.clips[number].width)
      event.target.setAttribute('height', this.clips[number].height)

      const canvas = document.querySelectorAll('.clip-canvas')[number],
            img = document.querySelectorAll('.clip-bgimage')[number]
      canvas.width = event.target.videoWidth
      canvas.height = event.target.videoHeight

      const context = canvas.getContext('2d')
      context.drawImage(event.target, 0, 0, event.target.videoWidth, event.target.videoHeight)

      let dataURL = canvas.toDataURL();

      img.setAttribute('src', dataURL)
    }
  }
}
</script>

<style scoped lang="scss">
#videowidgetapp .video-container {
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;

  .clip {
    position: relative;
    z-index: 3;
    width: 100%;
    margin: auto;
  }

  .clip-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: auto;
    opacity: 0.5;
    filter: blur(3px);
    z-index: 1;
  }
}

#videowidgetapp .widget-btn {
  display: inline-block;
  margin: 4px 5px;
  padding: 6px 12px 8px !important;
  border-radius: 15px;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  border-bottom: none;
  cursor: pointer !important;
  transition: all 0.5s ease;

  &:hover {
    color: #fff;
  }

  &:active, &:visited, &:visited:hover {
    // color: #fff !important;
  }
}
</style>
